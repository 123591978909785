* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Gilroy-Bold";
  src: local("Gilroy-Bold"), url(./fonts/Gilroy-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Medium";
  src: local("Gilroy-Medium"), url(./fonts/Gilroy-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"), url(./fonts/Gilroy-Light.ttf) format("truetype");
}

a{
  text-decoration: none !important;
  outline: none;
}

::-webkit-scrollbar {
  width: 10px; /* Set the width of the scrollbar */
}

/* Target the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background: linear-gradient(135deg, rgba(31, 0, 71, 1), rgba(31, 0, 71, 1));
  border-radius: 5px;
}

body {
  background: rgba(31, 0, 71, 1);
  background: url("./images/background.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* navigation style */
.navbar_container {
  padding: 3rem;
}

/* Herosection styles */
.heroSection_container {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 60%;
}
.hero_container {
  text-align: center;
  z-index: 9999 !important;
}

.leftImage {
  position: absolute;
  left: -280px;
  top: -50px;
  transform: scale(0.25);
}

.rightImage {
  position: absolute;
  right: -160px;
  top: 10%;
  transform: scale(0.3);
}
.heroSection_container .header {
  color: white;
  font-size: 72px;
  font-weight: bolder;
  font-family: "Gilroy-medium", sans-serif;
  letter-spacing: 2px;
}
.financial_content {
  color: rgba(0, 255, 167, 1);
  font-size: 72px;
  font-family: "Gilroy-medium", sans-serif;
}

.middle_content {
  color: rgba(255, 255, 255, 0.7);
  line-height: 30px;
  margin-top: 48px;
  padding: 0 80px;
  text-align: center;
  width: 600px;
  font-family: "Gilroy-light", sans-serif;
  font-size: 20px;
}

/* button styles */
.button_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* width: 22%; */
  margin-top: 50px;
  z-index: 999;
}
.learnMore {
  border: none;
  background: white;
  border-radius: 40px;
  width: 200px;
  font-family: "Gilroy-bold", sans-serif;
  height: 65px;
  padding: 1rem;
  outline: none;
  font-size: 16px;
  margin-right: 30px;
  cursor: pointer;
  text-align: center;
}
.learnMore:hover,
.joinTelegram:hover {
  transition: all ease-in-out 0.6s;
  transform: scale(1.1);
  opacity: 0.6;
}
.joinTelegram {
  border-radius: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  outline: none;
  align-items: center;
  font-family: "Gilroy-bold", sans-serif;
  height: 65px;
  text-decoration: none;
  font-size: 16px;
  padding: 10px 60px;
  cursor: pointer;
  flex-direction: row;
  border: 1px solid rgba(255, 252, 248, 0.2);
  background: rgba(255, 252, 248, 0.1);
  color: white;
}

/* Footer Styles */

.footer_container {
  padding: 3rem;
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.footer_contents {
  padding: 3rem;
  background: rgba(255, 252, 248, 0.1);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
  font-family: "Gilroy-medium", sans-serif;
  width: 100%;
  color: white;
}

.footer_images {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 999;
}
.footer_images img:hover {
  transition: all ease-in-out 0.6s;
  transform: scale(1.2);
  opacity: 0.6;
  cursor: pointer;
}
.footerRights {
  font-family: "Gilroy-light", sans-serif;
  text-align: center;
  font-size: 16px;
}

.footerRights2 {
  display: none;
  font-family: "Gilroy-light", sans-serif;
  text-align: center;
  font-size: 16px;
}

@media screen and (max-width: 1366px) {
  .left_image,
  .right_image {
    transform: scale(0.5);
  }
  .left_image {
    left: 0%;
  }
  .blurry_image {
    bottom: 25%;
    right: 10%;
  }
}

@media screen and (max-width: 1045px) {
  .left_image {
    left: -7%;
  }
  .right_image {
    right: 0%;
  }
  .blurry_image {
    bottom: 20%;
    right: 4%;
  }
}
@media screen and (max-width: 1045px) {
  .heroSection_container .header {
    font-size: 40px;
  }
  .financial_content {
    font-size: 40px;
  }
  .footerRights {
    font-size: 16px;
  }
  .middle_content {
    font-size: 16px;
  }
  .footer_logo {
    width: 120px;
    height: 29px;
  }
  .navbar_logo {
    width: 120px;
    height: 29px;
  }
}
@media screen and (max-width: 900px) {
  .middle_content {
    width: 500px;
  }
  .blurry_image {
    transform: scale(0.4);
    bottom: 10%;
  }
  .leftImage {
    left: -350px;
  }
  .footer_container {
    padding: 1rem;
  }
}
@media screen and (max-width: 760px) {
  .rightImage,
  .leftImage {
    display: none;
  }
  .blurry_image {
    transform: scale(0.4);
    bottom: 30%;
    right: -18%;
  }

  .footer_contents {
    flex-direction: column;
    gap: 20px;
  }
  .footerRights {
    display: none;
  }
  .footerRights2 {
    display: flex;
    font-size: 12px;
  }
  .joinTelegram {
    padding: 10px 40px;
  }
}

@media screen and (max-width: 540px) {
  .middle_content {
    line-height: 25px;
    padding: 3rem;
  }
  .footerRights {
    font-size: 12px;
  }
  .learnMore {
    width: 120px;
    height: 45px;
    font-size: 12.24px;
    font-weight: 700;
    line-height: 15.16px;
    font-family: "Gilroy-medium", sans-serif;
  }
  .joinTelegram {
    width: 150px;
    height: 45px;
    padding: 0;
    font-size: 12.24px;
    font-weight: 700;
    line-height: 15.16px;
    font-family: "Gilroy-medium", sans-serif;
  }
}
@media screen and (max-width: 400px) {
  body {
    background: url("./images/mobile_background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(31, 0, 71, 1);
  }
  .middle_content {
    line-height: 25px;
    width: 100%;
    padding: 0;
  }
  .blurry_image {
    right: -20%;
  }
}

@media screen and (max-width: 365px) {
  .heroSection_container .header {
    font-size: 48px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
  }
  .heroSection_container .financial_content {
    font-size: 48px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
  }
  .middle_content {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }
}
